<template>
  <div class="place-map">
    <l-map
        :zoom="zoom"
        :center="GET_PANEL.location"
        :options="{attributionControl: false, zoomControl: false}"
        :crs="crs"
        ref="map"
    >
      <l-tile-layer
          :url="url"
          :attribution="attribution"
      />

      <l-marker
          :lat-lng="location"
          :icon="iconPlace"
          :zIndexOffset="500">
      </l-marker>

      <l-marker
          :lat-lng="GET_PANEL.location"
          :icon="iconPanel"
          :zIndexOffset="500">
      </l-marker>

      <l-control-attribution :position="'bottomright'" :prefix="false"/>

      <l-control class="example-custom-control" :position="'bottomright'">
        <MapControls
            @zoomin="zoomIn"
            @zoomout="zoomOut"
            @setlocation="locateCenter"
        />
      </l-control>

      <l-control v-if="distance" class="example-custom-control" :position="'topleft'">
        <div class="distance mozzarella white--text rocky--bg">
          {{ makeDistance }}
        </div>
      </l-control>
    </l-map>
  </div>
</template>

<script>
import L, {CRS} from 'leaflet';
import {LControlAttribution, LMap, LTileLayer, LControl, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import MapControls from "@/components/TransportMap/MapControls/MapControls";
import {mapGetters} from "vuex";

export default {
  name: "PlaceMap",

  props: {
    data: {
      type: [Object, Array],
      default: () => [],
    },
    location: {
      type: [String, Array],
      default: () => ''
    }
  },

  components: {
    MapControls,
    LMap,
    LTileLayer,
    LControl,
    LMarker,
    LControlAttribution,
  },

  computed: {
    ...mapGetters(['GET_PANEL']),

    makeDistance() {
      if (this.distance) {
        return this.distance.replace('&#160;', ' ');
      }
      return null;
    }
  },

  mounted() {
    this.requestDistance();
    this.fitBounds();
  },

  data() {
    return {
      distance: null,
      url: 'https://core-renderer-tiles.maps.yandex.net/tiles?l=map&v=21.07.28-0-b210701140430&x={x}&y={y}&z={z}&scale=2&lang=ru_RU',
      attribution:
          `
          <div style="display: flex; align-items: center; font-size: 11px;">© Яндекс
          <a style="margin-left: 4px; color: black; font-size: 11px;" href='https://yandex.ru/legal/maps_termsofuse/?lang=ru'>Условия использования</a>
          <img style="margin: 0 4px; height: 14px;" src="/img/map/yandex-logo.svg"/></div>
        `,
      crs: CRS.EPSG3395,
      zoom: 14,

      iconPanel: L.icon({
        iconUrl: '/img/map/pins/icon-panel-location.svg',
        iconSize: [32, 32],
        iconAnchor: [16, 16]
      }),
      iconPlace: L.icon({
        iconUrl: '/img/map/pins/icon-rocky.svg',
        iconSize: [24, 36],
        iconAnchor: [12, 36]
      }),
    }
  },

  methods: {
    requestDistance() {
      this.$ymaps.route([{
        type: 'wayPoint',
        point: this.GET_PANEL.location
      }, {
        type: 'wayPoint',
        point: this.location
      }], {
        mapStateAutoApply: true
      }).then((route) => {
        this.distance = route.getHumanLength();
      });
    },

    fitBounds() {
      var bounds = new L.LatLngBounds([this.location, this.GET_PANEL.location]);
      const mapComponent = this.$refs.map;
      mapComponent.mapObject.fitBounds(bounds);
    },

    zoomIn() {
      const mapComponent = this.$refs.map;
      mapComponent.mapObject.zoomIn();
      this.zoom = mapComponent.mapObject.getZoom();
    },

    zoomOut() {
      const mapComponent = this.$refs.map;
      mapComponent.mapObject.zoomOut();
      this.zoom = mapComponent.mapObject.getZoom();
    },

    locateCenter() {
      this.fitBounds();
    }
  }
}
</script>

<style lang="scss">
.distance {
  width: auto;
  height: 24px;

  border-radius: 16px;
  padding: 4px 12px;
}

.place-map {
  height: 276px;
  width: 100%;
  border-radius: 16px;
  overflow: hidden;

}
</style>
