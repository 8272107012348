<template>
  <DetailedPageContainer>
    <div class="detailed__images" v-if="currentItem.images.length > 0">
      <r-row :wrap="false" class="px-4">
        <r-col :cols="{ widest: 4, wide: 4, middle: 2, narrow: 4 }"
               v-for="(img, index) in currentItem.images"
               :key="index">
          <r-image :url="img.replace('small', 'medium')"/>
        </r-col>
      </r-row>
    </div>

    <div class="detailed__title">
      <r-row class="px-4">
        <r-col :cols="{ widest: 4, wide: 4, middle: 2, narrow: 12 }">
          <div class="detailed__category mozzarella metropolis--text" v-if="category">
            {{ category }}
          </div>

          <h2 class="ricotta harakiri--text">
            {{ currentItem.title }}
          </h2>
        </r-col>
      </r-row>
    </div>

    <r-row class="px-4">
      <r-col :cols="{ widest: 4, wide: 4, middle: 2, narrow: 3 }">
        <div class="details" v-if="eventDates">
          <r-icon icon="calendar" fill="rocky"/>
          <span class="zamorano">
            {{ eventDates }}
          </span>
        </div>

        <div class="details" v-if="eventTime">
          <r-icon icon="clock" fill="rocky"/>
          <span class="zamorano">
            {{ eventTime }}
          </span>
        </div>

        <div class="details" v-if="address">
          <r-icon icon="geopoint" fill="rocky"/>
          <span class="zamorano">
            {{ address }}
          </span>
        </div>

        <QrCode v-if="website" :value="website" />
      </r-col>

      <r-col :cols="{ widest: 4, wide: 4, middle: 2, narrow: 3 }">
        <MoreText v-if="description" :text="description"/>
      </r-col>
    </r-row>

    <div class="detailed__map">
      <r-row class="px-4">
        <r-col :cols="{ widest: 4, wide: 4, middle: 12, narrow: 6 }">
          <PlaceMap :data="currentItem" :location="location"/>
        </r-col>
      </r-row>
    </div>

    <div class="detailed__additional-info" v-if="additions">
      <AdditionalInfo :data="additions"/>
    </div>

    <div class="detailed__more-items">
      <MoreItemsList :title="$t('more.more_events')"
                     :category="currentItem.category.id"
                     type="events"
                     :data-set="GET_EVENTS"
      />
    </div>
  </DetailedPageContainer>
</template>

<script>
import {mapGetters} from "vuex";
import DetailedPageContainer from "@/components/Containers/DetailedPageContainer";
import AdditionalInfo from "@/components/DetailedViewParts/AdditionalInfo/AdditionalInfo";
import MoreText from "@/components/DetailedViewParts/MoreText/MoreText";
import QrCode from "@/components/DetailedViewParts/QrCode/QrCode";
import MoreItemsList from "@/components/DetailedViewParts/MoreItemsList/MoreItemsList";
import PlaceMap from "@/components/DetailedViewParts/PlaceMap/PlaceMap";

export default {
  name: "DetailedEventView",

  components: {
    PlaceMap,
    MoreItemsList,
    QrCode,
    DetailedPageContainer,
    AdditionalInfo,
    MoreText,
  },

  mounted() {
    this.eventDatesAndTime();
  },

  computed: {
    ...mapGetters(['GET_EVENTS',]),

    currentItem() {
      return this.GET_EVENTS.filter(item => item.id == this.$route.params.id)[0];
    },

    category() {
      return this.currentItem?.category?.title;
    },

    location() {
      return this.currentItem?.place ? this.currentItem?.place?.location : this.currentItem?.location;
    },

    address() {
      return this.currentItem?.place ? this.currentItem?.place?.address : this.currentItem?.address;
    },

    website() {
      return this.currentItem?.contacts?.website;
    },

    description() {
      return this.currentItem?.description.replace(/<[^>]*>?/gm, '');
    },

    additions() {
      return this.currentItem?.additions;
    }
  },

  data() {
    return {
      eventDates: null,
      eventTime: null,
    }
  },

  methods: {
    eventDatesAndTime() {
      const startedAtDate = this.$moment(this.currentItem?.started_at * 1000).format('DD MMMM YYYY');
      const stoppedAtDate = this.$moment(this.currentItem?.stopped_at * 1000).format('DD MMMM YYYY');
      const startedAtTime = this.$moment(this.currentItem?.started_at * 1000).format('HH:mm');
      const stoppedAtTime = this.$moment(this.currentItem?.stopped_at * 1000).format('HH:mm');
      let currentCase = '';

      if ((startedAtDate === stoppedAtDate) && (startedAtTime === stoppedAtTime)) currentCase = 'datesAndTimeEqual';
      if ((startedAtDate !== stoppedAtDate) && (startedAtTime === stoppedAtTime)) currentCase = 'datesNotEqualAndTimeEqual';
      if ((startedAtDate === stoppedAtDate) && (startedAtTime !== stoppedAtTime)) currentCase = 'datesEqualAndTimeNotEqual';
      if ((startedAtDate !== stoppedAtDate) && (startedAtTime !== stoppedAtTime)) currentCase = 'datesAndTimeNotEqual';

      switch (currentCase) {
        case 'datesAndTimeEqual':
          this.eventDates = `${startedAtDate}`;
          this.eventTime = `${startedAtTime}`;
          break;
        case 'datesNotEqualAndTimeEqual':
          this.eventDates = `${startedAtDate} - ${stoppedAtDate}`;
          this.eventTime = `${startedAtTime}`;
          break;
        case 'datesEqualAndTimeNotEqual':
          this.eventDates = `${startedAtDate}`;
          this.eventTime = `${startedAtTime} - ${stoppedAtTime}`;
          break;
        case 'datesAndTimeNotEqual':
          this.eventDates = `${startedAtDate} - ${stoppedAtDate}`;
          this.eventTime = `${startedAtTime} - ${stoppedAtTime}`;
          break;
        default:
          break;
      }
    }
  },
}
</script>
